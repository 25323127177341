<template>
  <div class="app" v-loading="loading" >
    <div class="apap"  >
      <div class="img">
        <el-upload
          class="avatar-uploader"
          :action="baseApi+'/center/userinfo/updateimage'"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          name="files"
          :headers="httpheader"
          :on-progress = "oprogress"
          :before-upload="beforeUpload"
            v-loading="loading" 

        >
          <img
            v-if="imageUrl"
            :src="imageUrl"
            class="avatar"
            height="150"
            width="150"
          
          />
          <!-- <el-button size="small" type="primary" class="   
uploading">点击上传</el-button>   -->
           <img v-else  class="avatar"
            height="150"
            width="150" src="../../assets/home/nullimage.png" >
       
        </el-upload>
           <span class="click" @click="editpass">修改密码</span>
      </div>
      <div class="fromTable">
      <div class="table">
        <el-form
          :label-position="labelPosition"
          label-width="100px"
          :model="formLabelAlign"
        >
          <el-form-item label="昵称">
            <el-input v-model="formLabelAlign.nickname"></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="formLabelAlign.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" id="editphone">
   <el-input v-model="formLabelAlign.phone" disabled></el-input>
        <el-button @click="toRegister">修改</el-button>
          </el-form-item>
        
          <el-form-item label="性别">
              <el-radio v-model="formLabelAlign.sex" label="0">男</el-radio>
  <el-radio v-model="formLabelAlign.sex" label="1">女</el-radio>
          </el-form-item>
            <el-form-item label="生日">
            <!-- <el-input v-model="formLabelAlign.birthday"></el-input> -->
            <el-col>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formLabelAlign.birthday"
                style="width: 100%"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="居住地">
            <el-input v-model="formLabelAlign.address"></el-input>
          </el-form-item>
        </el-form>
      <div>
          <el-button type="primary" class="save  smname" @click="surename" v-if="!UseridentityStates">实名认证</el-button>

   <el-button type="primary" class="save  smname" v-else>已认证</el-button>

          <el-button type="danger" @click="updata" class="save">保存</el-button>
      </div>
      </div>
      </div>
    </div>
    <!-- 弹出 -->
    <MsgTip :dialogVisible="dialogVisible" @clear="clear" @sure="sure" :surenam="surenam" :editphone="editphone" :title="title" :editpas="editpas" :oldphone="formLabelAlign.phone"></MsgTip>
  </div>
</template>
<script>
import basecfg from "../../utils/applocationCfg"
import { Message } from "element-ui";
import updataApi from "../../api/updataApi";
import MsgTip from "../../components/center/entre/information.vue"
import userApi from "../../api/updataApi"
import imageZip from "../../utils/imageZip"
export default {
  data() {
    return {
      baseApi:basecfg.getBaseApi(),
      loading:false,
      editpas:false,
      title:"",
      editphone:false,
      surenam:false,
      dialogVisible:false,
      labelPosition: "right",
      UseridentityStates:false,
      formLabelAlign: {
        nickname: "",
        name: "",
        phone: "",
        sex: "0",
        birthday: "",
        address: "",
      },
      imageUrl: "",
      files: [],
      httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
      //   headerObj:{token:window.localStorage .getItem("token")},

      //   token:""
    };
  },
  components:{
    MsgTip
  },
  created() {

    this.gettoken();
    // 获取当前用户认证状态
    this.getUseridentityStates()
    this.information();
    var thes = this;
  },
  methods: {
    beforeUpload(file){
       const isJPG = file.type.indexOf("image")!=-1;
       const isLt2M = file.size / 1024 / 1024 < 4;
     
        if (!isJPG) {
          Message.error('请正确上传图片格式!');
        }
        if (!isLt2M) {
         Message.error('上传头像图片大小不能超过 4MB!');
        }
      return imageZip.beforeUpload(file)
    },
    // 上传时
    oprogress(){
      this.loading = true
    },
      // 获取当前用户认证状态
        getUseridentityStates(){
        userApi.getUseridentityStates("",res=>{
         this.UseridentityStates = res.data.result;
       
        })
      }, 
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.loading = false
     
    },
    fileChange: function (f) {
      let file = document.getElementsByName("img")[0].files[0];
      let thes = this;
      let formData = new FormData();
      formData.append("files", file, file.name);
      updataApi.updateImage(formData, function (res) {
        thes.image = res.data.result;
           
      });
    },
    //修改信息
    updata() {
      const that = this;
      updataApi.updateInfo(
        {
          address: this.formLabelAlign.address,
          birthday: this.formLabelAlign.birthday,
          name: this.formLabelAlign.name,
          nickname: this.formLabelAlign.nickname,
          sex: this.formLabelAlign.sex,
        },
        function (res) {
       
          if (res.data.code == 200) {
            Message({
              message: "保存成功",
              type: "success",
            });
            updataApi.getcurruserinfo("", (res) => {
              let nickName = res.data.result.nickname;
              that.$store.commit("initnickName", nickName);
            });
          }
        
        }
      );
      
    },

    gettoken() {
      this.token = (localStorage.getItem("Login_data"));
      this.$store.state.home.token = JSON.parse(localStorage.getItem("Login_data"));
    
    },
    information() {
      const that = this;
      updataApi.getcurruserinfo(null, function (res) {
      
        
        if (res.data.code == "200") {
          that.formLabelAlign = res.data.result;
          that.formLabelAlign.sex = res.data.result.sex + "";
          that.imageUrl = res.data.result.image;
        }
      });
    },
    // 修改手机号
    toRegister(){
       this.dialogVisible =true
       this.editphone = true
         this.surenam = false
          this.editpas = false
          this.title ="修改手机号";
    },
    clear(bool){
      this.dialogVisible = bool;
    },
    sure(bool){
      this.dialogVisible = bool;
    },
    // 实名认证
    surename(){
     
  this.dialogVisible =true
  this.surenam = true
   this.editpas = false
   this.editphone = false
    this.title ="实名认证";
    },
    editpass(){
        this.dialogVisible =true
         this.surenam = false
   this.editphone = false
   this.editpas = true
     this.title ="修改密码";
    },

  },
};
</script>

<style scoped lang="scss">

.apap{
  // width: 800px;
    // border: 1px solid red; 
    margin-top: 30px;
   /* margin-left: -150px; */
   display: flex;
}
.avatar{
  /* border: 1px solid red; */
  width: 91px;
  height: 91px;
  /* margin-top: 46px; */
  background: #D8D8D8;
border-radius: 5px;
}

.upload {
  /* border: 1px solid red; */
  margin-top: 100px;
  margin-left: 5px;
}

.click{
  color: rgb(0, 0, 0,0.59);
  display: block;
  margin-top: 30px;
  margin-left: 20px;
  font-size: 14px;
  cursor: pointer;
}

.fromTable{
  /* border: 1px solid red; */
  /* margin-left: 20px; */
  /* margin-top: -110px; */
 /*  position: relative;
  top: -110px; */
  /* vertical-align: top; */
  /* display: inline-block; */
  // width: 700px;
}
.table {
  /* border: 1px solid red; */
  /* margin-left: 100px; */
  width: 520px;
  height: 45px;
  font-size: 15px;
  
}
 ::v-deep .avatar{
 vertical-align: middle;
}

 .table .save {
   margin-top: 50px;
  margin-left: 100px; 
    width: 150px !important; 
   border: 0;
   /* background-color: rgb(102,177,255); */
}
.table .smname{
  background-color: white;
  color: rgb(245,108,108);
  border: 1px solid rgb(245,108,108);
}
.sex {
  /* border: 1px solid red; */
  width: 420px;
  height: 45px;
}
#editphone{
  // display: flex;
  
  // border: 1px solid red;
  .el-input{
    width: 330px;
    // float: left;
    display: inline-block;
    // border: 1px solid red;
  }
  .el-button{
      // float: right;
      // border: 1px solid red;
      margin-left: 10px;
       display: inline-block;
  }
  .el-button:hover{
   color: red;
   border:1px solid red;
  background-color: white;
  }
}
</style>