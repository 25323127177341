<template>
  <div class="tip">
    <el-dialog :title="title"
               :visible="dialogVisible"
               :before-close="close"
               :close-on-click-modal="false"
               width="25%">
     <!-- 修改手机号 -->
<el-form  label-width="80px" v-if="editphone">
  <el-form-item label="原手机号">
    <el-input v-model="oldphone" disabled></el-input>
  </el-form-item>
   <el-form-item label="新手机号">
    <el-input placeholder="请输入正确格式" v-model="phone"></el-input>
  </el-form-item>
   <el-form-item label="验证码">
    <el-input class="newcode" placeholder="请输入验证码" v-model="code"></el-input>
    <el-button @click="uphonecode">{{isRun?`${runTime}s`:"获取验证码"}}</el-button>
  </el-form-item>
</el-form>
<!-- 实名认证 -->
<el-form  label-width="80px" v-if="surenam">
  <el-form-item label="姓名">
    <el-input placeholder="请输入姓名" v-model="name"></el-input>
  </el-form-item>
   <el-form-item label="手机号">
    <el-input placeholder="请输入正确格式" v-model="mobile"></el-input>
  </el-form-item>
   <el-form-item label="身份证号">
       <div v-if="novisible" >
    <el-input class="nonewcode" placeholder="请输入..." type="password" v-model="idCard"></el-input>
   <img src="../../../assets/home/novisible.png" alt="" @click="anovisible">
       </div>
       <div v-else >
   <el-input class="nonewcode" placeholder="请输入..." type="text" v-model="idCard"></el-input>
   <img src="../../../assets/home/visible.png" alt="" @click="bnovisible">   </div>
  </el-form-item>
    <!-- <router-link to=/forget class="a">  <div class="find">密码忘了？找回密码</div> </router-link> -->
</el-form>
<!-- 修改密码 -->
<el-form  label-width="80px" v-if="editpas">
 <el-form-item label="原密码">
       <div v-if="novisibley" >
    <el-input class="nonewcode" placeholder="请输入..." type="text"  v-model="oldPwd"></el-input>
   <img src="../../../assets/home/visible.png" alt="" @click="anovisibley">
       </div>
       <div v-else >
   <el-input class="nonewcode" placeholder="请输入..." type="password" v-model="oldPwd"></el-input>
   <img src="../../../assets/home/novisible.png" alt="" @click="bnovisibley">   </div>
  </el-form-item>
   <el-form-item label="新密码">
       <div v-if="novisible" >
    <el-input class="nonewcode" placeholder="请输入..." type="password" v-model="newPwd"></el-input>
   <img src="../../../assets/home/novisible.png" alt="" @click="anovisible">
       </div>
       <div v-else >

   
   <el-input class="nonewcode" placeholder="请输入..." type="text" v-model="newPwd"></el-input>
   <img src="../../../assets/home/visible.png" alt="" @click="bnovisible">   </div>
  </el-form-item>
    <router-link to=/forget class="a">  <div class="find">密码忘了？找回密码</div> </router-link>
</el-form>
     
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary"
                   @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Message } from "element-ui";
import updataApi from "../../../api/updataApi"
export default {
  data () {
    return {
        novisible:true,
        novisibley:true,
        //旧密码
        oldPwd:"",
        // 新密码
        newPwd:"",
        // 新手机号
        phone:"",
        // 新手机号验证码
        code:"",
        // 姓名
        name:"",
        // 身份证号
       idCard:"",
        // 手机号
         mobile:"",
         isRun:false,
         runTime:"60",

    }
  },
  props: {
      title:"",
      editpas:false,
      surenam:false,
      editphone:false,
      oldphone:"",
    
    dialogVisible: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: '是否退出页面'
    },
  },
  created(){
      
  },
  mounted(){
   
  },
  methods: {
    clear () {
      this.$emit('clear', false);
       this.oldPwd=""
         this.newPwd=""
         this.phone="",
         this.code=""
         this.mobile=""
         this.idCard=""
         this.name=""
    },
    sure () {
      // 修改密码
     if(this.editpas){
     
       updataApi.updatePassword({oldPwd:this.oldPwd,newPwd:this.newPwd},res=>{
       
          if(res.data.code==501){
             Message.error('请求数据不对');
         }
          if(res.data.code!==200){
             Message.error('修改密码失败');
         }
          if(res.data.code==200){
              Message({
          message: '修改密码成功',
          type: 'success'
        });
          this.$emit('sure', false);
           this.$router.push("/login")
         }

       })
     }
    //  修改手机号
   else if(this.editphone){
     
       updataApi.updatePhone({phone:this.phone,code:this.code},res=>{
      
         
         if(res.data.code!==200){
         Message.error(res.data.message);
       }
            if(res.data.code==200){
              Message({
          message: '修改手机号成功',
          type: 'success'
        });
        
         this.$parent.information()
           this.$emit('sure', false);
         }
         })
     }
    // 实名认证
    else if(this.surenam){     
       if(!this.name){
            return Message.error('姓名不能为空');

       }
      //  手机验证
        if(!/^1\d{10}$/.test(this.mobile)){
         Message.error('手机号格式不正确');
          this.mobile=""
           return
         }
       // 身份证验证
          let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if(reg.test(this.idCard) === false) 
       { 
         Message.error("身份证不合法")
              ; 
         return
           } 
            this.$uweb.trackEvent("认证行为","点击","个人认证")
            updataApi.addUseridentify({name:this.name,idCard:this.idCard,mobile:this.mobile},res=>{
             
               if(res.data.code==200){
                    this.$uweb.trackEvent("认证行为","认证成功","个人认证")
                   Message.success("认证成功")
                   this.$parent.getUseridentityStates()
                  this.$emit('sure', false);
               }
               else{
                 
                 Message.error("认证失败")
               }
               
        })
       
     }
    },
    // 新手机号验证码
 uphonecode(){
   updataApi.sendUpPhoneCode({phone:this.phone},res=>{
     
     if(res.data.code==501){
           Message.error('请输入正确的手机号');
     }
     
     if(res.data.code==200){
             if(!/^1\d{10}$/.test(this.phone)){
         Message.error('手机号格式不正确');
          this.phone=""
         return
       
      }
      else{
         Message({
          message: '发送成功',
          type: 'success'
        });
         this.isRun = true;
      this.autoTime = setInterval(() => {
        if(this.runTime===0){
          this.runTime=30;
          this.isRun = false;
          clearInterval(this.autoTime)
          return
        }
        this.runTime--
        
      }, 1000);
    
      }
     }
   })
 },
    close(){
         this.$emit('clear', false);
         this.oldPwd=""
         this.newPwd=""
         this.phone="",
         this.code=""
         this.mobile=""
         this.idCard=""
           this.name=""
    },
    // 原密码不可见
    anovisibley(){
        this.novisibley = false
    },
    bnovisibley(){
    this.novisibley= true
    },
    // 不可见
    anovisible(){
    this.novisible = false
    },
    // 可见
    bnovisible(){
this.novisible= true
    }
  }
}
</script>
<style lang="scss" scoped>
  
.tip{
    ::v-deep.el-dialog{
    height: 450px ;
    min-width: 447px;
    }
    .el-form{
        width: 400px;
        margin: 0 auto;
        // border: 1px solid red;
        .el-form-item {
            .newcode{
                width: 200px;
                // border: 1px solid red;
            }
              .nonewcode{
                width: 280px;
                // border: 1px solid red;
            }
            .el-button{
                 width: 100px;
                 margin-left: 10px;
                
                 background-color: rgb(0,111,225);
                 color: white;
            }
           
             border-bottom: 1px solid rgb(233, 232, 232);
            ::v-deep .el-input .el-input__inner{
                 border: 0;
                 outline: 0;
             }
          
        }  
         .find{
                //   border: 1px solid red;
                  float: right;
                  color: rgb(76, 149, 218);
                  margin-bottom: 70px;
             }
           

    } 
     .dialog-footer{
              //  border: 1px  solid red;
               display: flex;
               justify-content: space-between;
               width: 70%;
               margin: 0 auto;
               button{
                    //  border: 1px  solid red;
               }
             }
}
</style>